.game-box-container-mobile {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.game-box-container-mobile .game-box {
	width: 100%;
	height: 100%;
	border-radius: 0;
	border: none !important;
}

.game-box-container-mobile .game-box-top {
	border-radius: 0;
}

.game-box-container-mobile .game-instructions p {
	max-width: 70%;
	padding: 1rem;
	margin: 0;
}

.mobile-landscape-container .game-box-container-mobile .game-area{
	height: 90%;
}

.mobile-landscape-container .game-heading h2{
	font-size: 1.2rem !important
}

.game-box-container-mobile .game-instructions-question-mark {
	position: absolute;
	z-index: 150;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	top: calc(17.1% - 1.5rem);
	right: 20vh;
	cursor: pointer;
	transition: 0.2s ease;
	border-width: 2px !important;
}

.game-box-container-mobile .game-instructions-question-mark h1 {
	font-weight: bold;
	font-size: 2rem;
	line-height: 2rem;
	margin: 0%;
}

.game-box-container-mobile .game-instructions-question-mark:hover {
	transition: 0.2s ease;
}
.game-box-container-mobile .game-instructions-visible {
	opacity: 1;
	z-index: 100;
	transition: 0.2s ease;
}

.game-box-container-mobile .game-instructions {
	height: 50%;
	width: 50%;
	top: 15%;
	border-radius: 10px;
	border-width: 2px !important;
}

.game-box-container-mobile .game-box-top {
	display: flex;
	align-items: center;
	overflow: visible;
}

.game-box-container-mobile .game-box {
	box-shadow: none;
}

.game-box-container-mobile .answer-status-banner {
	height: 60vh;
	margin-top: 10vh;
}

.game-box-container-mobile .game-area .answer-icon img:hover {
	transform: none;
}

.game-box-container-mobile .game-area {
	border-radius: 0;
}

.game-box-container-mobile .level-complete {
	width: 90% !important;
	height: 90% !important;
	overflow: hidden !important;
	position: relative;
}

.game-box-container-mobile .level-complete h2 {
	font-size: 1rem;
}

.game-box-container-mobile .level-complete button {
	max-height: 70% !important;
	padding: 0.1rem 1rem !important;
}

.game-box-container-mobile .level-complete a{
	max-height: 70% !important;
}

.game-box-container-mobile .level-complete a button{
	max-height: 100% !important;
	min-height: 100% !important;
}

.game-box-container-mobile .level-complete button img, .game-box-container-mobile .level-complete a img{
	/* width: auto !important; */
	height: 70%;
	margin-left: 1rem !important;
}
.game-box-container-mobile .background-image {
	top: 0;
	left: 0;
}

.game-box-container-mobile .game-box-level-complete {
	background-color: transparent !important;
}
