.tutorial-overlay{
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: 1s background;
    background: transparent;
    opacity: 0;
    overflow: hidden;
}

.tutorial-overlay-active {
    width: 100vw;
    height: 100vh;
    transition: 1s background;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items:last baseline;
    background: rgba(0, 0, 0, 0.75);
}

.tutorial-overlay-active .tutorial-content{
    display: flex;
    margin-bottom: 50px;
    align-items: center;
}

.tutorial-overlay-active .wiki-tutorial{
    height: 300px;
    margin-right: 20px;
    animation: bounce-up 0.5s 0.5s ease both;
}

.tutorial-overlay-active .tutorial-text{
    animation: fade-in 0.5s 0.9s ease both;
    width: 400px;
    min-height: 100px;
    background-color: white;
    border-radius: 10px ;
    border: 2px solid rgb(19, 174, 242);
    padding: 10px;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: justify;
    display: flex;
    flex-direction: column;
}

.tutorial-overlay-active .tutorial-text:after {
    content: "";
    width: 20px;
    height: 20px;
    background-color:white;
    display: block;
    transform: rotate(45deg);
    position: absolute;
    top: 20px;
    left: -11px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: rgb(19, 174, 242);
    border-radius: 0 50% 0 0 ;
    
}

.tutorial-overlay-active .active-element{
    position: fixed;
}

.tutorial-overlay-active .active-background {
    position: fixed;
    border-radius: 50%;
    animation: highlight 0.5s 1.2s ease both;
    padding: 20px;
    box-sizing: content-box;
}
.arrow {
    display: none;
}

.active-arrow-bottom{
    width: 70px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: -70px;
    left: 10px;
    animation: fade-in 0.5s 1.6s ease both;
    display: block;
}

.active-arrow-top {
    width: 70px;
    transform: rotateY(180deg) rotateZ(90deg);
    position: absolute;
    top: -70px;
    left: 10px;
    animation: fade-in 0.5s 1.6s ease both;
    display: block;
}

.hide-active{
    opacity: 0 !important;
}

.tutorial-overlay-active .active-element * {
    opacity: 1;
}

@keyframes bounce-up {
    0%{
        opacity: 0;
        position: relative;
        transform: translateY(50px);
    }
    50% {
        opacity: 1;
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
    
}

@keyframes pop-in {
    0%{
        opacity: 0;
        transform: scale(1.5);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes highlight {
    0% {
        background-color: transparent;
        transform: translateX(-20px) translateY(-20px) scale(1.5);

    }

    100% {
        background-color: rgba(255, 255, 255, 0.8);
        /* background-color: white; */
        transform: translateX(-20px) translateY(-20px) scale(1);
        border: 2px solid rgb(19, 174, 242);
    }
}