.language-switch {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    cursor: pointer;
}

.language-switch-button .languages-options .language,
.language-switch-button .selected-language {
    display: flex;
    padding: 7px;
    min-width: 75px;
    color: white;
}

.language-switch-button .selected-language{
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.15);
}

.language-switch .languages-options {
    position: absolute;
    display: none;
    overflow: hidden;
    flex-direction: column;
    top: calc(100%);
}


.language-switch-button .languages-options{
    background-color: rgba(0, 0, 0, 0.30);
    border-radius: 0 0 5px 5px;
}

.language-switch-button img {
    max-width: 25px;
    border-radius: 2px;
    margin-right: 7px;
}

.language-switch h3 {
    margin: 0;
}

.language-switch:hover .languages-options, .language-switch:hover .language {
    display: flex;
}

.language-switch-button:hover .selected-language {
    border-radius: 5px 5px 0 0;
}

.language-switch-button:hover {
    transform: none !important;
}

.language-switch-circle .languages-options .language img,
.language-switch-circle .selected-language img{
    width: 5vw;
    height: 5vw;
    max-width: 50px;
    max-height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20%;
    border-radius: 0 !important;
    z-index: 1;
    position: relative;
}

.language-switch-circle .selected-language{
    border-radius: 50%;
}
.language-switch-circle {
    color: rgb(252, 240, 230) ;
}

.language-switch-circle .languages-options .language::before,
.language-switch-circle .selected-language::before {
    content: "";
    display: inline-block;
    width: 5vw;
    height: 5vw;
    max-width: 50px;
    max-height: 50px;
    position: absolute;
    background-color: currentColor;
    border-radius: 50%;
}

.language-switch-circle .languages-options .language::before {
    border: 1px solid rgba(0, 0, 0, 0.15);
}


.language-switch-circle img{
    box-shadow: none !important;
    transform: none !important;
}

.language-switch-left .languages-options {
    left: calc(-100%);
    top: 0;
}

