.image-card-container {
	width: 100%;
	height: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-card {
	background-color: white;
	margin: 0 10px;
	padding: 5px;
	width: calc(25% - 10px);
	height: 90%;
	border-radius: 20px;
	box-shadow: 0px 5px 24px #69696959;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 200px;
}

.image-card img {
	margin: 0 auto;
	transition: 0.2s ease;
}

#check-answer {
	height: 10%;
	cursor: pointer;
	border: none;
	transition: 0.2s ease;
}

#check-answer:hover {
	opacity: 0.6;
	transition: 0.2s ease;
}
