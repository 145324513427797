.display-flex {
	display: flex;
}

.card {
	--background-color: #f2f2f2;
	--border-color: #464646;
	--text-color: #202020;

	position: relative;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
	border-radius: 28px;
	padding: 16px;
	border: 3px solid var(--border-color, #333);
	background-color: var(--background-color, #f2f2f2);
	color: var(--text-color, #202020);
	overflow: hidden;
}

.card__background-svg {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: auto;
	fill: var(--border-color, #464646);
	z-index: 0;
}

.card__button {
	color: #fff;
	background: linear-gradient(45deg, #57104d, #692160);
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.32);
	border-radius: 42px;
	font-family: "Yanone Kaffeesatz", sans-serif;
	font-family: "YanoneRegular";
	font-size:1rem;
	text-transform: uppercase;
	/* padding: 12px 32px; */
	padding: 0.75rem 1rem;
	border: none;
	width: 100%;
	max-width: 240px;

	cursor: pointer;
	will-change: transform;
	transition: transform 230ms ease-in-out, box-shadow 230ms ease-in-out;
}

.card__button:hover,
.card__button:focus {
	outline: none;
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.28);
	transform: translateY(-4px);
}

.card__body {
	z-index: 1;
	position: relative;
	display: flex;
	font-size: 1rem;
}

.card__title {
	font-size: 1.6rem;
	font-weight: 400;
}

.card__content {
	width: 66.6666%;
	height: 100%;
}

.card__content p {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.card__media {
	width: 33.3333%;
}

.card__media img {
	width: calc(100% - 16px);
	height: auto;
	margin: auto;
	display: block;
}

.card__score {
	display: flex;
	align-items: center;
	margin-right: 17%;
	margin-left: 16px;
	width: 50%;
}

.card__score > img {
	width: auto;
	height: 34px;
}

.card__score > p {
	font-size: 1.2rem;
	margin: 0;
}

.card__footer {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: space-between;
}
