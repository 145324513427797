.instructions-button {
    border-radius: 25px;
    box-shadow: inset 0px 5px 0px #ffffff84, inset 0px -5px 0px #00000049;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    overflow: hidden;
    cursor: pointer;
    z-index: 150;
}

.instructions-button>* {
    width: 44px !important;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}



.mobile-landscape-container .instructions-button{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(9% - 15px);
    position: relative;
    right: 0;
    top:0;
}

.mobile-landscape-container .instructions-button h1 {
    font-size:1.5rem;
}

@media screen and (min-width: 720px) and (min-height: 540px) {
    .mobile-landscape-container .instructions-button{
        height: 50px !important;
        width: 50px !important;
        top: calc(9% - 25px) !important;
        border-radius: 50px !important;
    }  
    
    .mobile-landscape-container .instructions-button h1{
        font-size: 2rem !important;
      }

}