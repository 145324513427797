button {
	transition: 0.2s ease;
}

button:hover {
	transform: translateY(-5px);
	transition: 0.2s ease;
}

.background-image {
	position: absolute;
	min-height: 100%;
	min-width: 100%;
	opacity: 0.5;
	object-fit: cover;
	text-align: center;
	z-index: -1;
	transform-origin: bottom;
	/* animation: image-background-animation 1.3s 2s ease both; */
}

@keyframes game-box-animation {
	0% {
		background: transparent;
	}
	100% {
		background: white;
	}
}

@keyframes container-animation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes image-background-animation {
	0% {
		transform: translateY(100%) scale(0);
	}
	100% {
		transform: translateY(0) scale(1);
	}
}
