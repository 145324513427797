.legal-container{
    width: 80vw !important;
    min-width: 600px;
    max-width: 1000px;
    background-color: #fff;
    padding: 2rem 3rem;
    border-radius: 5px;
    /* border: 3px solid rgb(167, 136, 255); */
    text-align: justify;
    margin: 0 auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    position: relative;
    top: -5vh;
    font-family: "Yanone Kaffeesatz",
        sans-serif;font-family: "YanoneRegular";
    font-size: 1.1rem;
    font-weight:lighter;
    letter-spacing: .05rem;
    color: rgb(47, 47, 57);

}

.legal-container h5{
    margin: 10px 0;
    color: rgba(47, 47, 57, 0.689);
    cursor: pointer;
    width: fit-content;
}

.legal-navbar{
    height: fit-content;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.legal-container p{
    /* margin:; */
     /* text-indent: 50px; */
}

.legal-title{
    height: 40vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-color:#0bcbff;
    color:white;
    letter-spacing: .2rem;
    font-size: 1.5rem;
    flex-direction: column;
    padding: 1rem;
}


.legal-title h1{
    font-size: 2rem;
    margin: auto 0;
}

.legal-title a{
    height: fit-content;
    margin: auto 0;
}

.prelucrarea-datelor .legal-title {
    background: #7E38AB;
}

.prelucrarea-platilor .legal-title {
    background: #45BF55;
}

.legal-title h1{
    text-align: center;
}

.cookies .legal-title{
    background: #FF901E;
}

.back-to-top{
    background-color: inherit;
    width:3rem;
    height:3rem;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border-radius: 50%;
    transition: 0.2s ease;
    cursor: pointer;
    opacity: 0;
    transform: scale(0);
    display: flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    z-index: 10;
}

.back-to-top h1{
    text-align: center;
    margin: 0;
    width: fit-content
}


.back-to-top:hover {
    filter: brightness(1.2);
    transform: scale(1.2) !important;
    transition: 0.2s ease;
}

.legal-table td{
    padding:0.5rem !important;
}

.legal-table{
    text-align: left;
}

.legal-table tr:first-child{
    font-weight: bold;
}

.legal-container a{
    color: rgb(0, 157, 220);
}

div.form-checkbox-container,
.form-submit {
    height: fit-content;
    margin: auto 0;
}

.form-checkbox-container {
    display: flex;
    align-items: center;
    color: rgb(128, 128, 128);
}

.form-checkbox-container label {
    padding-left: 0.5rem;
}

@media screen and (max-width: 1000px) {
    .legal-title h1 {
        font-size: 1.1rem;
        margin: auto 0;
    }
}