.fastforward-button-ro:after {
    content: "Verifica" !important;
}

.fastforward-button-eng:after {
    content: "Check" !important;
}

.back-button-ro:after {
    content: "Înapoi" !important;
}

.back-button-eng:after {
    content: "Back" !important;
}