.navbar-col,
.game-box-top-row .col-md-12 {
	padding: 0;
	margin: 0;
	height: 100%;
	position: relative;
}

.container {
	max-width: 100%;
	background: transparent;
	padding: 0;
	margin: 1% 0 0 0;
	border-radius: 12px;
	height: 89%;
}

.row {
	width: 100%;
	margin: 0;
	padding: 0;
}

.game-box-top-row {
	height: calc(10% + 20px);
}

.mobile-landscape-container .game-box-top-row{
	height: 10%;
}

/* .game-box-divider {
	height: 10%;
} */

.game-box-progress{
	height: 20px;
	display: flex;
	border-top: 3px solid;
	position: relative;
	/* border-bottom: 3px solid; */
	overflow: hidden;
}

.mobile-landscape-container .game-box-progress{
	height: 10px;
}

.progress-done{
	z-index: 2;
	transition: 0.3s ease;
	border-radius: 0 10px 10px 0;
	position: relative;
	overflow: hidden;
	/* box-shadow: inset -3px 3px 5px 1px rgba(255, 255, 255, 0.5) ; */
	/* height: 70%; */
	/* margin: auto 0; */
	/* width: 100%; */
}

.progress-not-done {
	opacity: 0.2;
	width: 100%;
	right: 0;
	height: 100%;
	position: absolute;
	transition: 0.3s ease;
}

.progress-done:after {
	content: "";
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: currentColor;
	/* background-image: url("../assets/banner_modules2.svg"); */
	background-size: auto 200%;
}

.progress-not-done:after {
	content: "";
	opacity: 0.7;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background-image: url("../assets/bara.png"); */
	background-size: auto 100%;
}

.game-area {
	max-height: 100%;
	height: calc(90% - 20px);
	padding: 1% 2%;
	border-radius: 15px;
}

.game-instructions {
	position: absolute;
	height: 30%;
	width: 30%;
	border-radius: 35px;
	top: 20%;
	right: 4%;
	z-index: -10;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	opacity: 0;
	display: flex;
	transition: 0.2s ease;
	min-width: 300px;
}

.game-instructions img {
	max-width: 30%;
}

.game-instructions p {
	max-width: 70%;
	padding: 1rem;
	margin: 0;
}

.game-instructions-question-mark {
	position: relative;
	z-index: 115;
	/* min-width: 3rem ;
	min-height: 3rem ; */
	max-width: 3rem ;
	max-height: 3rem ;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	/* top: calc(12.1% - 1.5rem); */
	right: -5%;
	cursor: pointer;
	transition: 0.2s ease;
}

.game-instructions-question-mark h1 {
	margin: 0%;
}

.game-instructions-question-mark:hover {
	transform: scale(1.2);
	transition: 0.2s ease;
}
.game-instructions-visible {
	opacity: 1;
	z-index: 500;
	transition: 0.2s ease;
}



#overlay {
	position: absolute;
	width: calc(100% - 4px);
	left: 3px;
	background-color: white;
	height: calc(90% - 26px);
	bottom: 3px;
	animation: 1s ease-in;
	border-radius: 0 0 15px 15px;
	transition: opacity 0.3s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: -100;
	opacity: 0;
}


#overlay.active-overlay{
	z-index: 105;
}

#overlay.visible-overlay {
	opacity: 1;
}
* {
	box-sizing: border-box;
}

.module-start-outer {
	background: #38daff 0% 0% no-repeat padding-box;
	box-shadow: 0px 14px 24px #00000059;
	border: 3px solid;
	border-radius: 15px;
	height: 100%;
}

.module-start-bg-inner {
	/* margin: 70px 150px 88px 88px; */
	background-image: url("../assets/icons_modules_and_bg/atentie.svg");
	background-size: contain;
	background-repeat: no-repeat;
	height: 100%;
	display: flex;
	text-align: justify;
	position: relative;
	width: 90%;
	margin: auto;
	min-width: 600px;
}

.module-start-image{
	width: 30%;
}
.module-start-image img{
	max-width: 100%;
	margin: auto;
}

.module-start-title {
	text-align: left;
	font: 3rem Yanone Kaffeesatz;
	font-family: "YanoneRegular";
	letter-spacing: 0px;
	line-height: 44px;
	margin: 0;
}

.module-start-description {
	text-align: left;
	font: 1.5rem Poppins;font-family: "PoppinsRegular";
	font-weight: lighter;
	letter-spacing: 0;
	line-height: 35px;
}

.module-start-score img {
	height: 7vh;
}

.module-start-score {
	display: flex;
	padding: 0.5rem;
}

.module-start-score > div {
	padding-left: 1rem;
}

.game-start-text-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width:70%;
	padding-left: 1rem;
}

.scoring-label {
	text-align: left;
}

.score-calculated {
	text-align: left;
}

.module-start-button {
	width: 12vw;
	margin-top: 1rem;
	margin-right: 1rem;
	height: 6vh;
	background: #57104d 0% 0% no-repeat padding-box;
	box-shadow: 0px 8px 12px #00000073;
	border-radius: 42px;
	opacity: 1;
	border: none;
	text-align: center;
	font: 1.5rem Yanone Kaffeesatz;font-family: "YanoneRegular";
	font-weight: lighter;
	letter-spacing: 0px;
	color: #ffffff;
	transition: 0.2s ease;
}

.module-start-button:hover {
	cursor: pointer;
	transform: translateY(-5px);
	transition: 0.2s ease;
}

/** =============== GAMES TEXT ====================== **/

.answer-status-banner {
	margin-top: -10vh;
	height: 50vh;
	max-height: 500px;
	left: 50%;
	transform: translate(-50%, 0);
	position: absolute;
	display: none;
	z-index: 110;
	background: transparent;
}

#congratulations-banner {
	width: 30vw;
}

.top-instruction {
	text-align: center;
	font: normal normal normal 2.625rem/2.188rem Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #232438;

	/*margin-top: -3vh;  TODO: Change this */
}

.top-instruction-divider {
	height: 5px;
	background: #32a47e 0% 0% no-repeat padding-box;
}

.check-button {
	position: absolute;
	right: 0;
	top: 50%;
	margin: auto 0;
	cursor: pointer;
	transform: translateY(-65%);
	background-color: transparent;
	border: none;
}

.check-button:hover {
	transform: translateY(-75%);
}

/** =================== ATENTIE ========================= **/
.question-box-atention-level-3 {
	width: 8.5vw;
	height: 5.28vh;

	border-radius: 5px;

	margin-top: 3vh;
}

.question-box-atention-level-3 .text {
	text-align: center;
	font: normal normal normal 1.5rem/2.8rem Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #ffffff;
}

.answer-icon-atention-level-3 {
	width: 8.5vw;
	height: 13.2vh;
	border-radius: 5px;
}

.answer-icon-atention-level-3 {
	transition: 0.2s ease;
}

.answer-icon-atention-level-3:hover {
	cursor: pointer;
	transition: 0.2s ease;
	transform: scale(1.2);
	/* opacity: 0.7; */
}

.box-margins {
	margin-left: 85px;
	margin-right: 55px;
}

.question-heading {
	height: 30%;
	display: flex;
	align-items: center;
	text-align: left;
	font: normal normal normal 2rem/2.5rem Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

.input-count {
	width: 10vw;
	margin-top: 6vh;
	margin-left: 0.781vw;
	margin-right: 0.781vw;

	border: 0;
	border-bottom: 8px solid #232438;
	text-align: left;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #000000;
	text-align: center;
}

.suggested-font {
	text-align: center;
	font: normal normal normal 1.2rem Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #000000;
	opacity: 0.81;
}

.suggested-divider {
	background: #232438 0% 0% no-repeat padding-box;
	margin-left: 15px;
	margin-right: 15px;

	height: 8px;
	width: 120px;
}

#correct-suggested-button {
	background-color: transparent;
	border: 1px solid #3ddc80;

	width: 100px;
	height: 50px;
}

#correct-suggested-button:hover {
	cursor: pointer;
	background-color: #3ddc80;
}

#wrong-suggested-button {
	background-color: transparent;
	border: 1px solid #fa3b7e;

	margin-left: 21px;

	width: 100px;
	height: 50px;
}

#wrong-suggested-button:hover {
	cursor: pointer;
	background-color: #fa3b7e;
}

.answer-buttons {
	margin-top: 4vh;
}

.grid-cell {
	width: 150px;
	height: 100px;

	margin: 0;
	padding: 0;
}

.grid-objects-findable .bordered-box {
	/*border: 5px solid #32A47E;*/
	/* border: 5px solid #003366; */
	text-align: center;
}

.grid-objects-findable img {
	width: 100%;
	height: 100%;
}

table.icons-grid,
table.grid-objects-findable {
	border-collapse: separate;
	border-spacing: 0;
	width: 100%;
	max-height: 80vh;
	border-radius: 15px;
	border: 2.5px solid currentColor;
	border-spacing: 0;
	overflow: hidden;
	z-index: 1;
}

table.icons-grid td,
table.grid-objects-findable td {
	/* max-width: 7.81vw;
  max-height: 15.83vh; */
	border: 2.5px solid currentColor;
	padding: 0;
	vertical-align: middle;
	background: transparent;
	z-index: -1;
}

/* table tr:first-of-type td:first-of-type,
table tr:first-of-type td:last-of-type,
table tr:last-of-type td:first-of-type,
table tr:last-of-type td:last-of-type {
	border: 2.5px solid currentColor;
} */
/*
table tr:first-of-type td:first-of-type {
	border-radius: 10px 0 0 0;
}

table tr:first-of-type td:last-of-type {
	border-radius: 0 10px 0 0;
}

table tr:last-of-type td:first-of-type {
	border-radius: 0 0 0 10px;
}

table tr:last-of-type td:last-of-type {
	border-radius: 0 0 10px 0;
} */

table.icons-grid td div.back:hover {
	opacity: 0.5 !important;
	padding: 0;
	transition: opacity 0.2s ease, padding 0.2s ease;
	cursor: pointer;
}

table.icons-grid td div.back {
	transition: opacity 0.2s ease, padding 0.2s ease;
}

table.icons-grid tr {
	padding: 0;
	margin: 0;
}

table.icons-grid td img,
table.grid-objects-findable td img {
	max-width: 100%;
	max-height: 100%;
	margin: 0;

	display: block;
}

table.icons-grid img,
table.grid-objects-findable img {
	max-width: 100%;
	height: auto;
	width: auto\\9; /* ie8 */
}


table.icons-grid{
	border: none;
	border-radius: 0;
}

table.icons-grid tr:first-of-type td:first-of-type,
table.icons-grid tr:first-of-type td:last-of-type,
table.icons-grid tr:last-of-type td:first-of-type,
table.icons-grid tr:last-of-type td:last-of-type{
	border-radius: 0;
}

table.icons-grid td{
	border: 2px solid currentColor;
}

table.icons-grid tr:first-of-type td{
	border-top: 3.5px solid currentColor;
}

table.icons-grid tr td:first-of-type {
	border-left: 3.5px solid currentColor;
}

table.icons-grid tr:last-of-type td {
	border-bottom: 3.5px solid currentColor;
}

table.icons-grid tr td:last-of-type {
	border-right: 3.5px solid currentColor;
}

table.icons-grid td.no-border-cell {
	border-top: none !important;
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
}

/* table.icons-grid tr:first-of-type td.no-border-cell {
	border-bottom: 2px solid currentColor !important;
}

table.icons-grid tr td.no-border-cell:first-of-type {
	border-right: 2px solid currentColor !important;
}

table.icons-grid tr:last-of-type td.no-border-cell {
	border-top: 2px solid currentColor !important;
}

table.icons-grid tr td.no-border-cell:last-of-type {
	border-left: 2px solid currentColor !important;
} */


.selected-answer {
	border: 3px solid #f7931e;
	opacity: 1;
	/*filter: contrast(160%);*/
}

.selected-answer-table {
	background-color: #c6ecf7 !important;
}

.path {
	width: 100%;
	height: 100%;

	background-image: url("../assets/games/atentie/atentie_game6_level1/path-bg-1.PNG");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

/** ============== PERCEPTIE VIZUALA ==================== **/
/* .main-object-cubes {
  text-align: center;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
} */

.game-box {
	box-shadow: 0px 14px 24px #00000059;
	border-radius: 15px;
	opacity: 1;
	height: 100%;
	background: white;
}

.game-box-top {
	height: calc(100% - 20px);
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	border-radius: 10px 10px 0px 0px;
}

.mobile-landscape-container .game-box-top{
	height: calc(100% - 10px);
}

.perceptie_banner {
	background-image: url("../assets/games/perceptie_vizuala/top-background.jpg");
}

.game-heading {
	text-align: left;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	padding: 0 5%;
}

.game-heading div {
	z-index: 10;
}

.top-main-icon {
	flex-direction: column;
	max-height: 90%;
	max-width: 20%;
	position: relative;
}

.limbaj-1 .top-main-icon{
	flex-direction: column;
	height: 70%;
	min-width: 100px;
	max-width: 20%;
	border: 3px solid #57104d;
	border-radius: 5px;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.limbaj-1 .top-main-icon img{
	border: none !important;
	max-width: 90%;
		max-height: 90%;
}

.limbaj-1 .answer-icon{
	max-height: 80%;
}

.top-main-icon img {
	height: 100%;
	padding: 0.5rem;
	max-width: 100%;
	height: 100%;
	max-height: 225px;
	border: 3px solid #57104d;
	border-radius: 5px;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto
}

.limbaj-word{
	margin: 0;
}

.answer-icon {
	height: 100%;
	max-height: 175px;
	width: 20%;
	text-align: center;
}

.answer-icon img {
	max-height: 100%;
	max-width: 100%;
	/* max-height: 100%; */
}

.possible-answer-container {
	width: 33%;
	position: relative;
}

.top-icon-container {
	width: 300px;
	position: relative;
}

/** == PERCEPTIE GAME 2 == **/

.black-image-filter {
	filter: brightness(0) opacity(0.7);
}

.dragzone-row .drag-drop,
.dropzone-row .dropzone {
	min-width: 10%;
}

.dropzone {
	background-color: transparent;
	border: dashed 4px transparent;
	border-radius: 4px;
	transition: background-color 0.3s;
}

.drop-target {
	background-color: transparent;
	border-color: transparent;
	border-style: solid;
}

.drag-drop {
	touch-action: none;
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px);

	transition: background-color 0.3s;
}

.drag-drop {
	z-index: 101;
}

/* .drag-drop.can-drop {
} */

.perceptie_game2 {
	height: 100%;
}

.perceptie_game2 .drag-drop {
	margin: 0;
}

.perceptie_game2 .dropzone {
	margin: 0;
}

/** == PERCEPTIE GAME 3 == **/

.puzzle-icon {
	margin-top: 4vh;
	width: 150px;
	height: 150px;
}

/** ================== MEMORIE VIZUALA ====================== **/

.object-suggested-parts-level2 {
	text-align: center;
}

.object-suggested-parts-level2 {
	transition: 0.2s ease;
}

.object-suggested-parts-level2:hover {
	cursor: pointer;
	transition: 0.2s ease;
	transform: scale(1.2);
	/* opacity: 0.7; */
}

.object-suggested-parts-level2 img {
	/* width: 5.2vw;
  height: 11.57vh; */
  border: 3px solid rgb(19, 49, 112);
  border-radius: 10px;
}

.game-area .answer-icon img {
	transition: 0.2s ease;
}

.game-area .answer-icon img:hover {
	cursor: pointer;
	/* opacity: 0.7; */
	transition: 0.2s ease;
	transform: scale(1.05);
}

.game-area .memorize-answer-icon img {
	transition: transform 0.3s ease, border 0.1s ease;
}

.game-area .memorize-answer-icon img:hover {
	cursor: pointer;
	transition: transform 0.3s ease, border 0.1s ease;
	transform: scale(1.3);
}

.memorize-question {
	text-align: left;
	font: normal normal normal 2rem/57px Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	/* margin-top: 20vh;
  margin-bottom: 26.42vh; */
}

/** == MEMORIE GAME 3 == **/

table.memorize-grid {
	border-collapse: separate;
	width: 15.99vw;
	height: 28.43vh;
	border-radius: 5px;

	text-align: center;
	border: 2px solid black;
	border-spacing: 0;
	overflow: hidden;
	z-index: 1;
}

table.memorize-grid td {
	max-width: 150px;
	max-height: 100px;
	border: 2.5px solid black;
	padding: 0;
	margin: 0;
	vertical-align: middle;
	text-align: center;
	background: transparent;
	z-index: -1;
}

table.memorize-grid tr:first-of-type td:first-of-type,
table.memorize-grid tr:first-of-type td:last-of-type,
table.memorize-grid tr:last-of-type td:first-of-type,
table.memorize-grid tr:last-of-type td:last-of-type {
	border: 2.5px solid black;
}
table.memorize-grid tr:first-of-type td:first-of-type {
	border-radius: 2px 0 0 0;
}

table.memorize-grid tr:first-of-type td:last-of-type {
	border-radius: 0 2px 0 0;
}

table.memorize-grid tr:last-of-type td:first-of-type {
	border-radius: 0 0 0 2px;
}

table.memorize-grid tr:last-of-type td:last-of-type {
	border-radius: 0 0 2px 0;
}

table.memorize-grid tr {
	padding: 0;
	margin: 0;
}

table.memorize-grid td img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;

	display: block;
}

table.memorize-grid img {
	max-width: 100%;
	height: auto;
	width: auto\\9; /* ie8 */
}
table.memorize-grid img {
	width: 4.01vw;
	height: 8.1vh;
}

.element-to-arrange img {
	width: 4.01vw;
	height: 8.1vh;
}

.resize-cells td {
	width: 7.88vw;
	height: 15.93vh;
}

/** ============== CUBURI ==================== **/
.counted-cubes {
	width: 20.83vw;
}

/** ============== EMOTII ==================== **/

.front .emotii-cell {
	padding: 0.3rem;
}

/* .card-container {
  width: 9vw;
  height: 9vw;
} */

/** ============== LIMBAJ ==================== **/
.selected-answer-limbaj {
	border: 2px solid #f7931e;
	border-radius: 5px;
	opacity: 1;
}
.selected-answer-limbaj-second {
	border: 2px solid #158ae3;
	border-radius: 5px;
	opacity: 1;
}

.check-button:hover {
	cursor: pointer;
	opacity: 0.6;
	transition: 0.2s ease;
}

.check-button {
	transition: 0.2s ease;
}

.images-container {
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
}

.limbaj-icons-container {
	height: 100%;
	padding: 2%;
	display: flex;
	justify-content: center;
}

.limbaj-icons {
	padding: 12px;
}

.limbaj-images-view {
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.question-box-limbaj-silabe {
	width: 175px;
	height: 175px;

	margin-top: 3vh;
}

.game-box-container-mobile .answer-icon-limbaj-silabe{
	font-size: 1.8rem;
	/* height: 2.4rem; */
}

.game-box-container-mobile .question-box-limbaj-silabe{
	margin: 0.6rem 0 0.3rem 0;
}

.answer-icon-limbaj-silabe {
	width: 175px;
	padding: 0.5rem;
	height: fit-content;
	max-height: 100%;
	border-radius: 5px;

	text-align: center;
	font: normal normal normal 42px/53px Yanone Kaffeesatz;font-family: "YanoneRegular";
	letter-spacing: 0px;
	color: #ffffff;
}

.answer-icon-limbaj-silabe:hover {
	cursor: pointer;
	opacity: 0.7;
}

.pad-15 {
	padding: 1.57vh 0.781vw;
}

/* ================ CARD FLIPPING ================== */
/*
.flip-card {
	transform-style: preserve-3d;
	cursor: pointer;
}

.card-container {
	transition: transform 0.5s;
	box-sizing: border-box;
	transform: rotateY(0deg);
	margin: 0 auto;
}

.front,
.back {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}


.front {
	transform: rotateY(180deg);
	background-image: url("../assets/games/emotii/cartonas_2.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
} */

.grid{
	display: grid;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: 100%;
	height: 100%;
	margin: 0 auto;
	top:0;
	gap: 10px
}

.r2-c2-grid{
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	aspect-ratio: 1/1!important;
}

.r2-c3-grid {
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	aspect-ratio: 3/2;
}

.grid > div {
	text-align: center;
	/* height: 90%;
	width: 90%; */
	margin: auto;
	border-radius: 5px;
}


.grid img {
	/* height: 100%; */
	padding: 0.5rem;
}

.vertical-align-top {
	vertical-align: top !important;
}

.selected-item{
	border: 2px solid currentColor;
	border-radius: 5px;
	opacity: 1;
	position: relative;
	background-color: white !important;
}

.selected-item *{
	z-index: 2;
	position: relative;
}

.selected-item::before{
	content: '';
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: currentColor;
	opacity: 0.15;
}

.text-option{
	border-radius: 5px;
	z-index: 10;
	cursor: pointer;
	border: solid 2px cornflowerblue;
	height: fit-content;
}
.words-history {
	text-align: center;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	width: 60%;
	margin: 0 1rem;
	border-radius: 5px;
	background-color: rgb(239, 233, 255);
	border: 2px dashed rgba(48, 0, 179, 0.663);
	max-height: 4rem;
	height: 100%;
	margin: auto 0;
	/* box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.28); */

}

.words-history p {
	font-size: 1.3rem;
	font-weight: bold;
	animation: pulse-in-2 0.6s ease;
	margin: 0;
}

.words-history-2{
	margin: 0 1rem;
	text-align: center;
	border-radius: 5px;
	background-color: rgb(239, 233, 255);
	border: 2px dashed rgba(48, 0, 179, 0.663);
	height: 100%;
	margin: auto 0;
	width: 20%;
}

.words-history-2 p {
	font-size: 1.3rem;
	font-weight: bold;
	animation: pulse-in-2 0.6s ease;
}


@keyframes flip-in {
	0% {
		transform: rotateY(180deg);
	}
	100% {
		transform: rotateY(0deg);
	}
}

@keyframes flip-out {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(180deg);
	}
}

.flip-to-front {
	animation: flip-out 0.5s ease both;
}

.flip-to-back {
	animation: flip-in 0.5s ease both;
}

@keyframes wrong-answer {
	0% {
		margin: 5% 10% 5% 0%;
	}
	25% {
		margin: 5% 0% 5% 10%;
	}
	50% {
		margin: 5% 10% 5% 0%;
	}
	75% {
		margin: 5% 0% 5% 10%;
	}
	100% {
		margin: 5% 10% 5% 0%;
	}
}

@keyframes animate-congratulations {
	0% {
		width: 60vh;
		display: none;
		top: -60vh;
	}
	70% {
		top: 20vh;
	}
	100% {
		display: block;
		width: 60vh;
		top: 10vh;
	}
}
@keyframes pulse-in-1 {
	0% {
		transform: scale(1)
	}

	25% {
		transform: scale(0.8)
	}

	50% {
		transform: scale(1.2)
	}

	75% {
		transform: scale(0.9)
	}

	100% {
		transform: scale(1)
	}
}
@keyframes pulse-in-2 {
	0% {
		transform: scale(1)
	}
	33% {
		transform: scale(1.3)
	}

	66% {
		transform: scale(0.9)
	}

	100% {
		transform: scale(1)
	}
}
