.vegan-burger-menu {
	width: 5vh;
	height: 3vh;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	right: 25px;
	z-index: 201;
	max-height: 20px;
	max-width: 40px;
}

.vegan-burger-menu div {
	width: 100%;
	height: 20%;
	background-color: #fff;
	transition: 0.2s ease;
	border-radius: 2px;
}

.navbar-popup {
	width: 0;
	/* height: min(100vh, 100vw); */
	height: 100vh;
	background-color: #38daff;
	position: fixed;
	z-index: 200;
	right: 0;
	top: 0;
	overflow: hidden;
	transition: 0.2s ease;
	border-bottom-left-radius: 14px;
	padding: 0 0 1rem 0;
}

.navbar-popup-wrap {
	opacity: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-items: center;
}

.navbar-popup-active {
	width: 50%;
}

.navbar-popup-active .navbar-popup-wrap {
	opacity: 1;
	transition: 0.2s 0.3s ease;
}

.navbar-popup-wrap > img {
	height: 25%;
	width: 50%;
}

div.navbar-popup-item {
	height: 15%;
	width: 70%;
	background-color: #fff;
	border: 2px solid #132459;
	border-radius: 5px;
	padding: 0.3rem 1rem;
}

.navbar-popup-item a {
	display: flex;
	height: 100%;
	align-items: center;
	text-transform: uppercase;
	color: #132459;
}

.navbar-popup-item a h2 {
	margin: 0;
}
