.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-page-container__mobile .game-description{
	max-width: 90%;
	height: fit-content;
	min-height: 50%;
}

.login-page-container__mobile .login-screen-nav{
	height: 80px;
	padding: 1rem;
	position: fixed;
	top:0;
	z-index: 10;
	width: 100%;
}

.login-page-container__mobile .landing-navigation>*{
	margin:0;
}


.login-page-container__mobile .game-description, .login-page-container__mobile .login-buttons>div.user-tab{
	font-size: 1.2rem;
}

.login-page-container__mobile .login-button{
	margin-top: 10px
}

.login-page-container__mobile .login-screen-nav > img{
	max-width: 45%;
}

.login-page-container__mobile .landing-nav-option{
	margin: auto 0;
	width: 45%;
	background-color:rgb(50, 176, 225);
	justify-content: flex-end;
}

.login-page-container__mobile .landing-nav-option-2{
	display: none;
}

.login-page-container__mobile .login-screen-nav{
	background-color: rgb(50, 176, 225);
}

.login-page-container__mobile .login-screen-nav div img{
	max-width: 90%;
}

.login-page-container__mobile .login-screen-nav div:hover {
	transform: scale(1);
}

.login-page-container__mobile .login-screen-section:not(#login-screen-intro){
	z-index: 5 !important;
}

.login-page-container__mobile #login-screen-intro{
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 10;
}

.mobile-legal .legal-container{
	min-width: auto;
	width: 95% !important;
	padding: 1.5rem;
	font-size: 0.8em;
}

.mobile-legal .legal-navbar a{
	font-size: 0.5em;
	text-align: center;
}

.mobile-legal .legal-navbar img {
	width: 30vw !important;
}

.mobile-legal .legal-navbar > div {
	max-width: 60%;
}

/* .popup {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(-100%);
	z-index: 20;
	font-size: 10px;
} */

.login-popup,
.register-popup {
	position: absolute;
	width: 90%;
	max-width: 400px;
	max-height: 500px;
	background-color: white;
	border: 4px solid #177dda;
	box-shadow: 0px 12px 24px #00000040;
	border-radius: 15px;
	height: 80vh;
	display: flex;
	flex-direction: column;
}

.login-popup {
	padding: 4em 3em;
}

.register-popup {
	padding: 2em 3em;
}

.register-popup-logo {
	height: 10%;
	text-align: center;
}

.login-popup-logo {
	text-align: center;
}


.register-popup-register-with {
	height: calc(2em + 5%);
}

.register-popup-register-with h3,
.register-popup-form-container h3 {
	height: 2em;
	margin: 0;
	padding: 0.3em 0;
	font-size: 1.3em;
	color: #3d2c40;
}

.login-popup-form-container h3 {
	height: 4em;
	margin: 0;
	padding: 1.3em 0;
	font-size: 1.7em;
	color: #3d2c40;
}

.register-popup-form-container h3 {
	font-size: 1.6em;
}

.register-popup-form-container {
	height: calc(95% - 3em);
	margin-top: 0.5em;
	border-top: 3px solid #f2f2f2;
}

/* .login-popup-form-container {
	height: fit-content;
} */

.close-popup {
	background-color: rgb(255, 82, 68);
	width: 2em;
	height: 2em;
	position: absolute;
	top: 1em;
	right: 1em;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 50%;
	transition: 0.2s ease;
}

.close-popup:hover {
	transition: 0.2s ease;
	transform: scale(1.2);
	cursor: pointer;
}

.close-popup:hover div:first-child {
	transition: 0.2s ease;
	transform: rotate(45deg);
}

.close-popup:hover div:nth-child(2) {
	transition: 0.2s ease;
	transform: rotate(135deg);
}

.close-popup div {
	height: 70%;
	background-color: white;
	width: 5%;
	position: absolute;
	transform: rotate(90deg);
}

.form-footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.form-footer h5{
	display: inline-block;
	margin: 8px 0;
}

@keyframes animated-popup {
	0% {
		transform: translateY(-150%);
	}

	33% {
		transform: translateY(20%);
	}

	66% {
		transform: translateY(-10%);
	}

	100% {
		transform: translateY(0);
	}
}
/* 
.login-page-buildings {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: -1;
}

.login-page-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.login-page-background {
	position: fixed;
	bottom: 0;
	max-height: 200%;
	left: 0;
	z-index: -2;
}

#login-screen-intro,
#login-screen-modules-scrollable {
	width: 100%;
	height: 100%;
}

#login-screen-intro {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.login-screen-nav {
	padding: 2%;
	display: flex;
	justify-content: center;
	max-height: 20%;
}

.login-screen-nav img {
	max-width: 60%;
}

#create-account-button,
#login-button,
.see-modules-button {
	height: 30%;
	box-shadow: 0px 15px 18px #00000040;
	border-radius: 14px;
	color: white;
	font-weight: lighter;
	font-size: 1.4em;
	font-family: Yanone Kaffeesatz;
	cursor: pointer;
	transition: 0.2s ease;
	padding: 0.5em;
	display: flex;
	width: 80%;
	display: flex;
	justify-content: center;
}

.login-buttons > div:hover,
.see-modules-button:hover {
	transform: scale(1.2);
	transition: 0.2s ease;
}

#login-button {
	border: 3px solid #0b89ab;
	background-color: #0bcbff;
}

#create-account-button {
	background-color: #ff006e;
	border: 3px solid #81093d;
}

.game-description {
	max-width: 80%;
	max-height: 50%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font: normal normal normal 1.5rem Yanone Kaffeesatz;
	color: white;
	flex-direction: column;
}

.landing-buttons {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	max-height: 30%;
	padding-bottom: 20px;
	font-size: 14px;
}

.landing-buttons div {
	display: flex;
	justify-content: center;
	align-items: center;
}

.see-modules-button {
	border: 3px solid #0b89ab;
	background-color: #38daff;
}
.see-modules-button img {
	margin-left: 20px;
}

#login-screen-modules {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15% 5%;
	width: 100%;
	height: 100%;
}

.module-card-login {
	width: 90%;
	box-shadow: 0px 14px 18px #00000059;
	border: 4px solid;
	border-radius: 10px;
	text-align: center;
	position: relative;
	margin-bottom: 20%;
	font-size: 1.5rem;
}

.module-card-login h3 {
	margin: 0;
	font: normal normal normal 1.2rem Yanone Kaffeesatz;
}

.module-card-login p {
	line-height: 1.1rem;
}

.background-card {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	max-height: 200%;
	width: 100%;
	max-width: 100%;
}

.module-icon {
	height: 30%;
	max-width: 40%;
}

.module-description {
	height: 80%;
	width: 100%;
	position: relative;
	bottom: 15%;
}

.module-card-content {
	width: 100%;
	height: 120%;
	transform: translateY(-15%);
	padding: 0 20px;
	cursor: default;
}

.module-card-content h3 {
	font-size: 2rem;
}

.module-card-content p {
	font-size: 1.1rem;
	line-height: 1.4rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 8;
	-webkit-box-orient: vertical;
}

.login-form-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.register-with-container {
	height: calc(100% - 2em);
	display: flex;
	align-items: flex-end;
}

.register-with-container > div {
	cursor: pointer;
}

#register-google-button {
	height: 100%;
	width: 75%;
	background-color: #177dda;
	border-radius: 5px;
	align-items: center;
	display: flex;
	color: white;
	font-family: "Gudea", sans-serif;
	font-weight: bold;
	position: relative;
	transition: 0.2s ease;
}

#register-google-button:hover {
	transition: 0.2s ease;
	color: #177dda;
	border: 2px solid #177dda;
	background-color: white;
}

#register-google-button:hover svg {
	fill: #177dda;
}

#register-google-button svg {
	height: 1.5em;
	width: auto;
	max-height: 60%;
	padding: 0 20%;
}

#register-facebook-button,
#register-apple-button {
	height: 100%;
	margin: 0 1.25%;
	overflow: hidden;
	border-radius: 5px;
	border: 2px solid white;
	pointer-events: none;
}

#register-facebook-button svg,
#register-apple-button svg {
	height: 100%;
	width: auto;
	transition: 0.2s ease;
	pointer-events: auto;
}

#register-facebook-button svg:hover {
	transition: 0.2s ease;
	fill: #0f4d86;
}

#register-submit-button {
	font-size: 1.2em;
}

form.register-form,
form.login-form {
	display: flex;
	flex-direction: column;
	font-family: "Gudea", sans-serif;
	font-size: 1.3em;
}

form.register-form {
	height: calc(100% - 2em);
}

form.login-form {
	height: calc(100% - 4em);
}

form.register-form > *,
form.login-form > * {
	width: 100%;
	height: fit-content;
	margin: 0.5rem 0;
}

div.form-input-container {
	border: 1px solid #e4e4e4;
	display: flex;
	flex-direction: column;
	padding: 0.7rem;
	border-radius: 0px 0px 5px 5px;
	line-height: 2em;
}
div.form-input-container label {
	font-size: 1em;
}

div.form-checkbox-container,
.form-submit {
	height: fit-content;
	margin: auto 0;
}

form.register-form input,
form.login-form input {
	outline: none;
	border: none;
	width: 100%;
	padding: 0;
}

form.register-form input::placeholder,
form.login-form input::placeholder {
	text-align: left;
	color: #8d95b6;
	font-family: "Gudea", sans-serif;
}

#terms-and-conditions,
#remember-me {
	width: fit-content;
	display: inline-block;
}

#login-rememberme-input-container label,
#register-checkbox-input-container label {
	font-size: 1.3em;
}

.form-checkbox-container {
	display: flex;
	align-items: center;
	color: rgb(128, 128, 128);
	font-size: 0.7em;
}

.form-checkbox-container label {
	padding-left: 0.5em;
}

.form-submit {
	background-color: #ff006e;
	cursor: pointer;
	color: white;
	font-family: Yanone Kaffeesatz;
	height: fit-content;
	padding: 1em;
	border-radius: 5px;
	border: none;
	transition: 0.2s ease;
	width: 80%;
}

.form-submit:hover {
	background-color: #c00053;
	transform: scale(1.1);
	transition: 0.2s ease;
}
#register-name-input-container,
#register-username-input-container {
	width: 48%;
}
#register-username-input-container {
	margin-left: auto;
}

#login-submit-button {
	background-color: #0bcbff;
	color: white;
	font-family: "Yanone Kaffeesatz", sans-serif;
	font-size: 1.2em;
}

#login-gotoregister-button {
	color: white;
	font-family: "Yanone Kaffeesatz", sans-serif;
	text-align: center;
	margin-top: 1em;
	font-size: 1.2em;
}
*/